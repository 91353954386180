import { Injectable } from '@angular/core';
import { User } from './models/user.model';
import { Lookup } from './models/lookup.model';

@Injectable()
export class AppDataProvider {
  public user: User;
  public lookupData: Array<Lookup>;

  constructor() {}

  setUser(user: User) {
    this.user = user;
  }

  setLookupData(data) {
    this.lookupData = data;
  }
}
